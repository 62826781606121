import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const horse_back_riding = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'HORSE BACK RIDING | HB Privileged',
      title: 'HORSE BACK RIDING',
      subtitle: 'TOUR OVERVIEW',
      text: "Like you have never done before! Get on board our luxurious shuttle van towards one of the most magnificent spots of our Bay. Located about 20 minutes away from Vallarta you will find what us locals call “The Desembocada”, a magical place where we will give you a taste of several different tequilas and raicilla so that emboldened, we can start our journey through the jungle, by the river to where it gets deeper so we can swim with the horse. A truly unique experience to do this this in the back of these animals. Afterwards, come join us for a carne asada in the ranch, where you can make your own guacamole and salsa and enjoy it with delicious handmade tortillas. Then, relax by the imposing swimming pool, in the jacuzzi or in the healing thermal waters of the ranch. Everything is included! We assure you that this is a face of Vallarta that only the experts can find through our travel agency. Reserve now since they have limited space!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'PASEO A CABALLO | HB Privileged',
      title: 'PASEO A CABALLO',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Como nunca lo has hecho antes! Aborda nuestra transportación de lujo rumbo a unos de los lugares más magnificos de la Bahía. Localizado a unos 20 minutos de Vallarta encontrarás lo que los locales le llamamos “La Desembocada”, un lugar mágico en donde primero te daremos a probar varios tipos de Tequila y Raicilla para que después envalentonados emprendamos el camino a caballo por la orilla del río hasta un donde el agua se haga más profunda y podamos nadar con él. Una experiencia única el poder surcar el río en el lomo de uno de estos animales. Después acompáñanos a comer carne asada en el rancho donde puedes hacer tu propio guacamole y salsa y degustarla con deliciosas tortillas a mano. Luego, relájate a la orilla de su imponente alberca, en el jacuzzi o en las curativas aguas termales del lugar. Todo está incluído! Te aseguramos que es una parte de Vallarta que solo los expertos  ́pueden encontrar a través de nuestra agencia de viajes. Reserva cuanto antes pues el cupo es limitado!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/horse-back-riding1.jpg`,
    `${BASE_URL}/tours/horse-back-riding2.jpg`,
    `${BASE_URL}/tours/horse-back-riding3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/horse-back-riding.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default horse_back_riding;